import * as React from 'react'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Sidebar from './sidebar'
import { Link } from 'gatsby'
import Localization from './localization'
import { useLanguage } from '../context/language';
import { generateUrl } from '../utils';

const Layout = ({ children }: { children: any }) => {
  const { title, image } = useSiteMetadata()

  const [active, setActive] = React.useState(false);

  const { language } = useLanguage();

  const handleToggleBtn = () => {
    setActive(!active);
  }
  const handleDialog = (e: any) => {
    if (e.target.tagName == 'A') setActive(false);
  }

  const checkWindowSize = () => {
    return window.innerWidth >= 768;
  };
  React.useEffect(() => {
    const handleResize = () => {
      if (checkWindowSize()) {
        setActive(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div>
      <div className={`bg-gray-100`}>
        <header className="mx-auto max-w-screen-xl flex items-center justify-between px-4 md:px-8">
          <Link
            to={generateUrl('', language)}
            className='flex items-center hover:text-gray-600'
          >
            <img src={image} alt="Logo" className="w-12 md:w-14" />
            <span>Hello BKK</span>
          </Link>
          <Localization />
        </header>
        <div className='bg-gray-100 py-4 md:py-8 mb-4 md:mb-8 text-gray-900 px-4 md:px-8'>
          <h2 className="mb-2 text-center text-2xl font-bold lg:text-3xl">{title}</h2>
          <p className="mx-auto max-w-screen-md text-center md:text-lg">
            {
              language == 'en'
                ? 'We have collected the difficulties that we often face when we first arrived in Bangkok...'
                : 'ဘန်ကောက် ရောက်ခါစ ကြုံရတတ်တဲ့ အခက်အခဲတွေကို စုစည်းထားတာပါ...'
            }
          </p>
        </div>
        <main className="mx-auto max-w-screen-xl px-4 md:px-8 pb-4 md:pb-8">
          <div className='flex flex-row'>
            <div className="basis-1/4 hidden md:block">
              <Sidebar />
            </div>
            <div className="flex-1">
              <div className="md:border-l-2 border-indigo-500 md:pl-6">
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`fixed inset-0 flex items-center justify-center md:hidden mx-4 ${active ? 'fixed' : 'hidden'}`} onClick={(e) => handleDialog(e)}>
        <dialog open className="rounded-md w-full shadow-2xl max-h-screen overflow-y-auto">
          <Sidebar />
        </dialog>
      </div>
      <button
        className="fixed bottom-4 right-4 inline-block rounded-full border border-indigo-600 bg-indigo-600 p-3 text-white hover:border-indigo-300 z-50 md:hidden"
        onClick={handleToggleBtn}
        aria-label='menu'
      >

        <svg
          className="h-5 w-5 rtl:rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </button>
    </div>
  )
}

export default Layout
