import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useLanguage } from '../context/language';
import { generateUrl } from '../utils';

const Sidebar = () => {
  const { language } = useLanguage();
  const data = useStaticQuery(graphql`
query {
    allMdx(sort: {frontmatter: {tag: ASC}}) {
      group(field: {frontmatter: {tag: SELECT}}) {
        nodes {
          frontmatter {
            tag
            title
            slug
            lang
          }
        }
      }
    }
  }
`)
  const transformTag = (input: string) => {
    const inputString = input.replace(/[^a-zA-Z0-9]/g, ' ').trim();
    const words = inputString.split(' ').filter(word => word.length > 0);
    let result = "";
    for (let i = 0; i < words.length; i++) {
      if (i > 0) result += " ";
      result += words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return result;
  }

  return (
    <div className="flex flex-col bg-white p-4 rounded-md">
      <ul className="space-y-1">
        <li>
          <Link
            to={generateUrl('', language)}
            className="block rounded-lg pe-4 pb-2 font-medium text-gray-500 hover:text-gray-700"
          >
            Home
          </Link>
        </li>
        {
          data.allMdx.group.map(group => (
            <li key={group.nodes[0].frontmatter.tag}>
              <details className="group [&_summary::-webkit-details-marker]:hidden" >
                <summary
                  className="flex cursor-pointer items-center rounded-lg text-gray-500 hover:text-gray-700"
                >
                  <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="font-medium ps-2">{transformTag(group.nodes[0].frontmatter.tag)}</span>
                </summary>
                <ul className="my-2 pl-4 space-y-3">
                  {
                    group.nodes.map(node => (
                      node.frontmatter.lang == language && (
                        <li key={node.frontmatter.title}>
                          <Link
                            to={generateUrl(node.frontmatter.slug, language)}
                            className="block rounded-lg pl-4 text-gray-500 hover:text-gray-700"
                          >
                            {node.frontmatter.title}
                          </Link>
                        </li>
                      )
                    ))
                  }
                </ul>
              </details>
            </li>
          ))
        }
      </ul>
    </div>
  )
}
export default Sidebar
