import "./src/styles/global.css"
import React from 'react';
import { LanguageProvider } from "./src/context/language";

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>
    {element}
  </LanguageProvider>
);

