import * as React from 'react';
import { langs, flags } from '../locale/languages';
import { navigate } from 'gatsby';
import { useLanguage } from "../context/language";

const Localization = () => {
  const { changeLanguage } = useLanguage();
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';

  const handleClick = (lang: "en" | "mm") => {
    let targetUrl;
    if (lang === 'en') {
      targetUrl = currentPath === '/mm/' ? '/' : currentPath.replace(/^\/mm/, '');
    } else {
      targetUrl = currentPath.startsWith('/mm') ? currentPath : `/mm${currentPath}`;
    }
    changeLanguage(lang);
    navigate(targetUrl);
  };

  return (
    <div className="flex flex-row md:justify-end justify-center">
      <div className="flex flex-row items-center right-1">
        <a
          href='https://www.facebook.com/mg.paing07'
          target='_blank'
          className="px-1 flex flex-row items-center text-sm font-medium focus:outline-none"
          aria-label='Contact to admin'
        >
          <svg className='w-9 h-9' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z" /><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z" /></svg>
        </a>
        {langs.map((lang) => (
          <button
            onClick={() => handleClick(lang)}
            className="px-1 flex flex-row items-center border border-gray-200 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:bg-gray-200 focus:outline-none"
            key={lang}
          >
            <span className="text-md">{lang}</span>
            <span className="ml-1 rounded-full overflow-hidden">
              <img src={flags[lang]} alt={`${flags[lang]} flag icon`} className="w-8 h-8" />
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Localization;
