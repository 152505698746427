import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

type LanguageType = "en" | "mm";

type LanguageContextType = {
  language: LanguageType;
  changeLanguage: (lang: LanguageType) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<LanguageType>('en'); // Default to 'en'

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentPath = window.location.pathname;
      const initialLang = currentPath.startsWith('/mm') ? 'mm' : 'en';
      setLanguage(initialLang);
    }
  }, []);

  const changeLanguage = (lang: LanguageType) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    return { language: 'en', changeLanguage: () => {} };
  }
  return context;
};
